section.projects {
  max-width: 1200px;
  margin: auto;

  header {
    background-image: linear-gradient(179.55deg, rgba(0, 0, 0, 0) 0.39%, rgba(0, 0, 0, 0) 0.4%, rgba(0, 0, 0, 0.8) 99.61%), url("../img/pages_img/hangar.jpg");
    height: 350px;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0 0 20px 20px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    margin-bottom: 130px;
  }

  h3 {
    margin-bottom: 50px;
    padding: 10px;
  }

  h2 {
    text-align: left;
  }

  figure {
    display: flex;
    max-height: 400px;
    max-width: 1200px;
    margin: auto;
    gap: 20px;
    margin-bottom: 20px;
    
    .wrap2 {
      display: flex;
      width: 75%;
      flex-direction: column;
      gap: 20px;

      div.budomatex {
        border-radius: 20px;
        box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);      
        background-image: url("../img/logo/budomatex.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        height: calc(400px / 2 - 20px);
        width: 100%;
        background-origin: content-box;
        padding: 20px;
        box-sizing: border-box;
      }

      .wrap4 {
        display: flex;
        gap: 20px;

        .strus {
          border-radius: 20px;
          box-shadow: 0px 0px 25px rgba(0,  0, 0, 0.2);      
          background-image: url("../img/logo/strus.jpg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          height: calc(400px / 2 - 20px);
          width: 100%;
          background-origin: content-box;
          padding: 20px;
          box-sizing: border-box;
        }
      }
    }

    div.ssm {
      border-radius: 20px;
      box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);      
      background-image: url("../img/logo/SSM.jpg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      height: 380px;
      width: 25%;
      background-origin: content-box;
      padding: 20px;
      box-sizing: border-box;

      
    }
  }

  
  p {
    font-size: 22px;
    font-weight: 500;
    padding: 20px 50px;
    box-sizing: border-box;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2); 
    border-radius: 20px;
    margin-bottom: 100px;
    text-align: center;
  }
  
  img {
    margin: auto;
    display: block;
    margin-bottom: 100px;
  }

  ul {
    list-style-image: url("../img/icons/rectangle.png");
  }

  
  div.examples1 {
    display: flex;
    gap: 50px;
    justify-content: space-around;
    padding-left: 15px;

    h3 {
      text-align: left;
      margin-bottom: 20px;
    }

    li {
      line-height: 30px;
      font-size: 18px;
      list-style-position: inside;
    }
  }

  div.examples2 {
    text-align: center;
    margin: 50px auto;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    
    h3 {
      text-align: center;
      margin-bottom: 20px;
    }
    
    ul {
      line-height: 30px;
      font-size: 18px;
      text-align: left;
      align-self: center;
    }
    
  }

  section.gallery {
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    

    h3 {
      color: white;
      background-color: #5C2FFE;
      border-radius: 10px;
      padding: 10px 0;
      margin-top: 120px;
      width: 90%;
    }

    div.galleryGrid {
      display: flex;
      flex-wrap: wrap;
      // height: 1000px;
      max-width: 1000px;
      gap: 20px;
      justify-content: center;
      
      div {
        width: 300px;
        height: 300px;
        background-color: gray;
        border-radius: 20px;
        background-size: cover;
        transition: .2s;
        box-sizing: border-box;

        &:hover {
          transform: scale(1.1);
          border: #5C2FFE solid 3px;

        }

        &.gal1 {
          background-image: url("../img/gallery/gal1.JPG");
        }
        &.gal2 {
          background-image: url("../img/gallery/gal2.JPG");
        }
        &.gal3 {
          background-image: url("../img/gallery/gal3.JPG");
        }
        &.gal4 {
          background-image: url("../img/gallery/gal4.JPG");
        }
        &.gal5 {
          background-image: url("../img/gallery/gal5.JPG");
        }
        &.gal6 {
          background-image: url("../img/gallery/gal6.JPG");
        }
        &.gal7 {
          background-image: url("../img/gallery/gal7.JPG");
        }
        &.gal8 {
          background-image: url("../img/gallery/gal8.JPG");
        }
        &.gal9 {
          background-image: url("../img/gallery/gal9.JPG");
        }
      }
      
    }
  }
}

@media screen and (max-width: 900px){
  section.projects {
    header {
      border-radius: 0px;
      height: 200px;

      h2 {
        font-size: 11vw;
        line-height: 11vw;
      }
     
    }

    figure {
      flex-direction: column;
      align-items: center;

      max-height: max-content;
      div.wrap2 {
        width: 100%;
      }

      div.ssm {
        width: 300px;
        height: 200px;
      }
    }

    div.examples1 {
      flex-direction: column;
      align-items: center;
      padding-left: 15px;
    }
    
  }
}