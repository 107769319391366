section.contact {
  max-width: 1200px;
  margin: auto;
  // min-height: calc(100vh - 63px - 60px - 120px);

  header {
    background-image: linear-gradient(
        179.55deg,
        rgba(0, 0, 0, 0) 0.39%,
        rgba(0, 0, 0, 0) 0.4%,
        rgba(0, 0, 0, 0.8) 99.61%
      ),
      url("../img/pages_img/telephone.jpg");
    height: 350px;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0 0 20px 20px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    margin-bottom: 130px;
  }

  figure {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 180px;
    flex-direction: row;
   

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        height: 120px;
        width: 120px;

      }
      
      p {
        margin-top: 10px;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  section.contact {

    header {
      border-radius: 0px;
      margin-bottom: 100px;

      h2 {
        font-size: 11vw;
        line-height: 11vw;
      }
    }

    figure {
      flex-direction: column;
      gap: 50px;
    }
  }
}
