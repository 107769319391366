// font import 
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// standarization
* {
  font-family: "noto sans", sans-serif;
  margin: 0;
  padding: 0;
}

$main: #5C2FFE;

body {
  background-color: white;
  overflow-x: hidden;
}

h2 {
  font-size: 64px;
  color: white;
  margin-bottom: 40px;
  line-height: 66px;
}

h3 {
  font-size: 34px;
  text-align: center;

}

main {
  margin-top: 63px;
}


.active {
  color: #5C2FFE;
}


// footer

footer {
  display: flex;
  background-color: #444;
  color: white;
  font-size: 14px;
  height: 60px;
  justify-content: center;
  align-items: center;
  margin-top: 120px;



  a {
    color: #4c7ffe;
    padding-left: 50px;
    font-weight: 600;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}