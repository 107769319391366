section.about {
  max-width: 1200px;
  margin: auto;

  header {
    background-image: linear-gradient(
        179.55deg,
        rgba(0, 0, 0, 0) 0.39%,
        rgba(0, 0, 0, 0) 0.4%,
        rgba(0, 0, 0, 0.8) 99.61%
      ),
      url("../img/pages_img/windows.jpg");
    height: 350px;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0 0 20px 20px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    margin-bottom: 130px;
  }

  div.wrapper {
    display: flex;
    justify-content: space-around;
    gap: 20px;
    align-items: flex-start;
    margin-bottom: 100px;

    article {
      box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
      border-radius: 20px;
      padding: 20px;
      width: 30%;

      h3 {
        text-align: left;
        margin-top: 10px;
      }

      p {
        margin-top: 15px;
      }
    }
  }

  figure {
    display: flex;
    max-width: 500px;
    margin: auto;
    align-items: center;
    justify-content: center;

    p {
      max-width: 400px;
      text-align: center;
      font-weight: 600;
      font-size: 20px;
    }
  }
}

@media screen and (max-width: 900px) {
  section.about {
    header {
      border-radius: 0px;
      height: 200px;

      h2 {
        font-size: 11vw;
        line-height: 11vw;
      }
    }

    div.wrapper {
      flex-direction: column;
      align-items: center;
      article {
        width: 80%;

        h3 {
          font-size: 24px;
        }
      }

    }
    figure {
      flex-direction: column;
      gap: 10px;
    }
  }
}
