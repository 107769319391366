section.home {
  max-width: 1200px;
  margin: auto;
  
  header {
    background-image: linear-gradient(179.55deg, rgba(0, 0, 0, 0) 0.39%, rgba(0, 0, 0, 0) 0.4%, rgba(0, 0, 0, 0.8) 99.61%), url("../img/pages_img/skyscrapers.jpg");
    height: 400px;
    background-position: center top;
    background-repeat: no-repeat;
    border-radius: 0 0 20px 20px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    margin-bottom: 130px;


    p {
      font-size: 18px;
      color: white;
      max-width: 600px;
    }
  }

  div.offer {
    display: flex;
    flex-direction: column;
    gap: 70px;

    h3 {
      text-align: center;
    }

    article {
      display: flex;
      box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
      height: 300px;
      width: 100%;
      border-radius: 20px;
      align-items: center;


      div {
        height: 300px;
        width: 50%;
        background-repeat: no-repeat;
        background-position: center;
      }

      p {
        text-align: center;
        width: 50%;
        box-sizing: border-box;
        padding: 50px;
        font-size: 18px;
      }
      
      &.art1 {
        div {
          background-image: url("../img/offer_img/worker.jpg");
        }
      }

      &.art2 {
        flex-direction: row-reverse;
        div {
          background-image: url("../img/offer_img/architecture.jpg");
        }
      }

      &.art3 {
        div {
          background-image: url("../img/offer_img/engineer.jpg");
        }
      }
    }

    p.check {
      font-weight: 500;
      text-align: center;
      font-size: 20px;

      .link {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  section.home {

    header {
      border-radius: 0px;
      height: 350px;

      h2 {
        font-size: 11vw;
        line-height: 11vw;
      }
      p {
        font-size: 16px;
      }
    }

    div.offer {
      
      article {
        flex-direction: column;
        height: auto;

        div {
          width: 100%;
          border-radius: 20px 20px 0 0;
          background-size: cover;
        }

        p {
          width: 100%;
        }

        
      }

      article.art2 {
        flex-direction: column;
      }
    }
  }
}