section.notFound {
  display: flex;
  height: calc(100vh - 243px);
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
  img {
    margin-bottom: 30px;
    height: auto;
    width: 200px;
  }

  h2 {
    color: rgba(0, 0, 0, 0.542);
    margin-bottom: 20px;
  }
  
  p {
    color: rgba(0, 0, 0, 0.542);
    font-size: 18px;
  }

  .back {
    color: white;
    background-color: #5C2FFE;
    padding: 10px 20px;
    border-radius: 20px;
    text-decoration: none;
    font-size: 20px;
    font-weight: 500;
    margin-top: 50px;
  }
}