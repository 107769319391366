nav.navDesktop {
  height: 60px;
  font-size: 20px;
  border-bottom: 3px solid #5C2FFE;
  position: fixed;
  top: 0;
  background-color: white;
  min-width: 100vw;
  z-index: 2;
  box-shadow: 0 0 15px rgba(34, 34, 34, 0.329);
  
  div.navWrap {
    display: flex;
    max-width: 1180px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;

    img.logo_main {
      display: block;
      height: 100%;
    }

    div.links {
    display: flex;

      .link {
        text-decoration: none;
        color: black;
        line-height: 60px;
        transition: .2s;
        font-weight: 600;
        margin: 0 20px;

        &:hover {
          color: #5C2FFE;
        }
      }
    }

    .burger {
      display: none;
    }

    
  }
}

div#menu.false {
  width: 0vw;
  position: fixed;
  display: flex;
  left: -100vw;
  transition: .2s;

  .link {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  nav.navDesktop {

    div.navWrap {
      align-items: center;
      height: 60px;
      justify-content: space-between;

      div.links {
        display: none;
      }

      img.burger {
        display: block;
        z-index: 2;
        padding: 13px 20px;
      }

    }
  }

  
  div#menu.true {
    left: 0;
    position: fixed;
    width: 100vw;
    background-color: #5C2FFE;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 50px;
    align-items: center;
    transition: .2s; 

    .link {
      color: white;
      text-decoration: none;
      font-size: 30px;
      font-weight: bold;
      transition: .2s;
      transform: translateY(-70px);

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}